<template>
  <section id="get-in-touch">
    <v-row align="center" justify="center" no-gutters>
      <v-col class="text-center pa-10" cols="12">
        <br />
        <br />
        <br />
        <br />
        <br />

        <v-row align="center" justify="center" no-gutters>
          <base-heading class="black--text"> NUESTRO EQUIPO </base-heading>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" md="2">
        <TeamMember
          name="Ady Castro"
          job="Directora"
          description="Investigador médico, PhD en Salud Publica, experto en EPOC y Salud
        Respiratoria, asesor científico APEPOC. Con casi 20 años de experiencia
        en investigación médica de los determinantes de salud y enfermedad a
        nivel poblacional, y más de 15 años dedicados a las enfermedades
        respiratorias y crónicas como la EPOC."
          link="https://www.linkedin.com/in/adycastro/"
          img="https://media-exp1.licdn.com/dms/image/C4E03AQHmApxhyP-J3Q/profile-displayphoto-shrink_200_200/0/1607076525998?e=1649894400&v=beta&t=lgPSMYLGJKUWHzimpJ2W1mWAH6VzssZUuhzsu-2djxM"
        />
      </v-col>
      <v-col cols="10" md="2">
        <TeamMember
          name=" Enrique Sánchez Bonet"
          job="Desarrollo"
          description=" Ingeniero Superior en Informática, especializado en el desarrollo con
          tecnologías out service, principalmente Google y AWS . Desarrollador
          frontend y Full Stack e IOT."
          link="https://www.linkedin.com/in/enrique-sanchez-bonet-a90378138/"
          img="https://media-exp1.licdn.com/dms/image/C4D03AQGKSwiQZ2HT3w/profile-displayphoto-shrink_800_800/0/1554971464066?e=1649894400&v=beta&t=A_l-yQKVMcIvrqRycC4qHSHVNtuZO1zexaxCChGLPxk"
        />
      </v-col>
      <v-col cols="10" md="2">
        <TeamMember
          name="Eduardo Cermeño"
          job="Producto"
          description="Doctor en Ciencias de la Computación y Telecomunicaciones. Profesor de la Universidad Autónoma de Madrid y cofundador de empresas relacionadas con IA (Vaelsys)."
          link="https://www.linkedin.com/in/eduardo-cerme%C3%B1o-phd-7992293/"
          img="https://media-exp1.licdn.com/dms/image/C4E03AQGpcrl5nXGbNA/profile-displayphoto-shrink_800_800/0/1516294578667?e=1649894400&v=beta&t=I08AmqTT9k_l3OtheNLeegXVJm-qdnCtkZw0Edfw8-o"
        />
      </v-col>
      <v-col cols="10" md="2">
        <TeamMember
          name="Nicole Hass"
          job="Desarrollo de Negocio"
          description="Doctora en análisis de tendencias sociales en salud (UNED), Nicole es una de las mayores expertas a nivel europeo en la gestión de vinculación de pacientes en programas de salud. Ha colaborado con diferentes entidades como el Institute of Patient Experience, Grifols o la asociación de pacientes de EPOC en España (APEPOC)."
          link="https://www.linkedin.com/in/nicolehass1/"
          img="https://media-exp1.licdn.com/dms/image/C4E03AQH7EaGDuLvdPA/profile-displayphoto-shrink_800_800/0/1517016020556?e=1649894400&v=beta&t=GFEW51GqcNZd7uiAWFzH-JfFxl_WrBW3GF1kXumWEVs"
        />
      </v-col>
      <v-col cols="10" md="2">
        <TeamMember
          name="Viktoria Rozhko"
          job="Comunicación"
          description="Técnico superior en anatomía patológica, Viktoria tiene experiencia en la gestión de plataformas de venta online y redes sociales. También ha trabajado en el desarrollo de materiales de divulgación para asociaciones de pacientes EPOC (APEPOC)"
          link="https://www.linkedin.com/in/nicolehass1/"
          img="https://scontent-mad1-1.cdninstagram.com/v/t51.2885-19/s150x150/269698145_857954578232111_5531456031667264068_n.jpg?_nc_ht=scontent-mad1-1.cdninstagram.com&_nc_cat=111&_nc_ohc=J-Fm2OTfgfgAX_3w-jt&edm=ABfd0MgBAAAA&ccb=7-4&oh=00_AT8wbKXUXbl57ZPKXio8rYJ80b9vf-wWXcOPlmhqZrEYrg&oe=6207E922&_nc_sid=7bff83"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BaseHeading from '@/components/base/Heading'
import TeamMember from '@/components/landing/TeamMember'

export default {
  name: 'GetInTouch',
  components: {
    BaseHeading,
    TeamMember
  }
}
</script>
