<template>
  <v-hover v-slot="{ hover }">
    <v-card height="620" class="mx-auto my-12">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-img :aspect-ratio="1 / 1" :src="img">
        <v-expand-transition>
          <v-row
            no-gutters
            align="center"
            justify="center"
            v-if="hover"
            class="
              d-flex
              transition-fast-in-fast-out
              blue
              darken-2
              v-card--reveal
              text-h2
              white--text
            "
            style="height: 100%"
          >
            <v-btn icon :href="link" target="_blank">
              <v-icon x-large color="white darken-2"> mdi-linkedin </v-icon>
            </v-btn>
          </v-row>
        </v-expand-transition></v-img
      >

      <v-card-title>{{ name }}</v-card-title>
      <v-card-subtitle>{{ job }}</v-card-subtitle>

      <v-card-text>
        <div>
          {{ description }}
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    job: {
      type: String
    },
    link: {
      type: String
    },
    img: {
      type: String
    },
    description: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    selection: 1
  }),

  methods: {
    reserve() {
      this.loading = true

      setTimeout(() => (this.loading = false), 2000)
    }
  }
}
</script>
